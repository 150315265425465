import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaEthereum, FaTelegram, FaTelegramPlane } from "react-icons/fa";
import { RiTwitterXLine,  } from "react-icons/ri";
import { BsPlayFill,  } from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet, AiFillFile, AiFillStar } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Hero = ({ pryColor
    , secColor }
) => {
    AOS.init({ once: true });

   

    const buttonStyle = "bg-[#fff] flex flex-row items-center w-full gap-2 text-[#000] py-1 px-10 md:px-14 text-12px md:text-[14px] hover:text-[#fff] transform duration-300 hover:bg-[#8C1B1A] rounded-full font-bold"

    return (<div className=" h-screen flex flex-col items-center justify-center gap-5 w-[60%] text-center mx-auto text-[#f2f2f2]">

    <div className="logo">
        <img src="../img/logo.png" className="w-[450px]" alt="logo" />
    </div>
    <h1 className="text-[35px] font-bold ">Miley Cyrus On Sol</h1>

        <p className="text-light text-sm">CA: 4XKGqiXQ8vz4iNtXfgW62oBS29L4JyyLhKHd64HYQUKv</p>
        <p className="text-light text-sm text-center">Hey yo! looks who is backkkkkkkkkkk. Miley Cyrus right on Solana Network! Things about to get exciting. It's partey time!</p>
 
 <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
    <a href="https://t.me/mileycyrusonsol"><button className={buttonStyle}><FaTelegramPlane /> Telegram</button></a>
    <a href="https://dexscreener.com/4XKGqiXQ8vz4iNtXfgW62oBS29L4JyyLhKHd64HYQUKv"><button className={buttonStyle}><FaEthereum /> Dexscreener</button></a>
    <a href="https://x.com/mileycyrusonsol"><button className={buttonStyle}><RiTwitterXLine /> Twitter</button></a>
 </div>



    </div>);
}

export default Hero;