import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";
const Whitepaper = () => {
    return ( 
        <div className="w-[100vw] flex flex-col h-screen items-center justify-center">
            <div className="">
                <img src="../img/whitepaper.jpg"    alt="" />
            </div>
            <Link to='/'>
                <FaArrowLeft />
            </Link>
            </div>
     );
}
 
export default Whitepaper;