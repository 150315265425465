import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";
const Memes = () => {
    const imageCount = 26;
    const images = [];
    for (let i = 1; i <= imageCount; i++) {
      images.push(`../img/pic-${i}.jpg`);
    }
    return (<div className="w-[100%] flex flex-col  items-center justify-center">
        <div className="w-full grid grid-cols-2 md:grid-cols-4">
            {images.map((image, index) => (
                <div key={index} className="aspect-w-1 aspect-h-1">
                    <img
                        src={image}
                        className="object-cover w-full h-full"
                        alt={`pic-${index + 1}`}
                    />
                </div>
            ))}
        </div>
        <Link to="/" className="my-5 mb-10"><FaArrowLeft /> </Link>
    </div>);
}

export default Memes;