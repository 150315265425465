import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const Videos = () => {
    return (<div className="w-[100%] gap-3 flex flex-col  items-center justify-center">
        <div></div>
        <div className="border-[3px] rounded-lg border-[#1e1e1e]">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/HSCfqS65pmY?si=6bKQOo1wmNRvmvH2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="border-[3px] rounded-lg border-[#1e1e1e]">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/OxmQU9Dvb7c?si=RrPzNjFP83eP6Ta6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <Link to="/" className="my-5 mb-10"><FaArrowLeft /> </Link>
    </div>);
}

export default Videos;