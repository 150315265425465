import React from 'react';
import { motion } from 'framer-motion'
import { useState } from 'react';

let dir;
const MarqueeImage = ({ imgData, delay, direction, duration }) => {
    const [isHovered, setIsHovered] = useState(false);
    if (direction === 'right' || 'left') {
        dir = 'row'
    } else if (direction === 'up' || 'down') {
        dir = 'col'
    }

    const handleMouseHover = () => {
        setIsHovered(true);
    } 
    

    return (
        <div className='overflow-hidden whitespace-nowrap w-full' onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover}>

            <motion.div
                style={{ display: 'inline-block' }}
                animate={isHovered ? {} : direction === "left" ? { x: ['100%', '-100%'] } : direction === "right" ? { x: ['-100%', '100%'] } : direction === 'up' ? { y: ['-100%', '100%'] } : direction = "down" ? { y: ['100%', '-100%'] } : ''}
                transition={{ duration: duration, repeat: Infinity, ease: 'linear', delay: delay }}
                
            >
                <div  className={`flex ${dir === 'row' ? 'flex-row' : 'flex-col'} gap-2`}>

                    {
                        imgData.map((item) =>
                        ((
                            <img src={item} key={item}  className='w-[40%] md:w-[200px] transform hover:rotate-2 hover:scale-90 hover:border-[2pxs]' alt={item} />
                        ))
                        )
                    }

                </div>

            </motion.div>
        </div>
    );
}

export default MarqueeImage;